import React from "react"
import { graphql } from "gatsby"
import { Container, SEO, BlogList } from "../components"
import { Capitalize } from "../components/shared"

export default function TagPagesTemplate({ pageContext, data }) {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const title = "Tag"
  const special = Capitalize(tag)

  return (
    <Container title={`Blog Posts with Tag - ${tag}`}>
      <SEO />
      <BlogList
        title={title}
        special={special}
        count={totalCount}
        posts={edges}
      />
    </Container>
  )
}

export const pageQuery = graphql`
  query TagsQuery($tag: String!) {
    allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          frontmatter {
            title
            date
            path
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
